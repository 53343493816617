import { Controller } from "@hotwired/stimulus"
import { useIntersection } from "stimulus-use"
import WordCloud from "wordcloud"

export default class extends Controller {
  declare listValue: string
  static values = {
    list: String,
  }

  stop: () => void = () => {
    WordCloud.stop()
  }
  isVisible = false

  connect() {
    const [_, stop] = useIntersection(this, { threshold: 0 })
    this.stop = stop
  }

  appear() {
    this.stop()
    const domElement = this.element
    WordCloud(domElement as HTMLElement, {
      minSize: 5,
      backgroundColor: "rgba(255,255,255,0,01)",
      shape: "diamond",
      shrinkToFit: true,
      list: this.list(),
    })
  }

  list() {
    return JSON.parse(this.listValue || "[]")
  }
}
